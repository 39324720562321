import React from 'react'
import Container from '../components/Container'
import Meta from '../components/Meta'
import BreadCrumb from '../components/BreadCrumb'
import { Link } from 'react-router-dom';

const ShippingPolicy = () => {
  return (
    <>
      <Meta title={"Shipping Policy"} />
      <BreadCrumb title="Shipping Policy" />
      <Container class1="policy-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="policy">
              <h2 class="text-xl font-semibold mb-2">1. Shipping Methods</h2>
              <p>We offer various shipping methods to ensure your order reaches you in a timely and secure manner. The available shipping options and their associated delivery times and costs will be displayed during the checkout process.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">2. Order Processing Time</h2>
              <p>2.1. Order processing typically takes 2-5 business days after the successful placement of your order. During peak seasons or promotions, processing times may be slightly longer, and we appreciate your patience.</p>
              <p>2.2. Please note that orders placed during holidays or festive periods may be processed on the next business day.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">3. Shipping Time</h2>
              <p>3.1. Shipping time refers to the time it takes for your order to be in transit from our warehouse to your specified shipping address.</p>
              <p>3.2. Estimated shipping times for different shipping methods are as follows:</p>
              <ul>
                <li>Inside Kathmandu-Valley: 2 to 7 business days</li>
                <li>Outside Kathmandu-Valley: 2 to 10 business days</li>
              </ul>
              <p>3.3. Please be aware that these are estimated shipping times, and actual delivery times may vary based on factors beyond our control, such as carrier delays, customs clearance, or inclement weather.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">4. Shipping Costs</h2>
              <p>4.1. Shipping costs are calculated based on the total weight of your order, the shipping method selected, and your delivery location. Shipping costs will be displayed during the checkout process before you confirm your order.</p>
              <p>4.2. Free shipping may be offered for orders exceeding a certain minimum purchase amount, and eligibility will be determined based on the subtotal after any discounts or promotions have been applied.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">5. Tracking Your Order</h2>
              <p>5.1. Once your order is placed, you will receive a confirmation call containing a tracking. You can check the status of your package in my-orders page.</p>
              <p>5.2. Please note that status may not be immediately available after you pay the required amount, as it may take some time for our system/team to update the status.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">6. Shipping Restrictions</h2>
              <p>6.1. We currently ship within Nepal only. If you are located outside of these areas, we regret that we are unable to fulfill your order at this time.</p>
              <p>6.2. Certain items, such as hazardous materials or products restricted by international shipping regulations, may not be eligible for shipping to certain locations.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">7. Address Accuracy</h2>
              <p>7.1. It is your responsibility to provide accurate and complete shipping information when placing your order. Please double-check the shipping address to avoid any delays or delivery issues.</p>
              <p>7.2. Defy Lifestyle shall not be held responsible for delays or non-delivery of orders caused by inaccurate or incomplete shipping information provided by the customer.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">8. Order Status Updates</h2>
              <p>8.1. You will receive order status updates via website throughout the shipping process, including order confirmation, shipping confirmation, and delivery confirmation.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">9. Lost or Delayed Packages</h2>
              <p>9.1. In the rare event that your package is lost or significantly delayed during transit, please <Link to='/contact' className='underline'>contact</Link> our customer support team so that we can assist you in resolving the issue.</p>
              <p>9.2. If a package is marked as delivered but you have not received it, we recommend checking with neighbors or local postal carriers to ensure it was not misdelivered.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">10. Customs and Duties</h2>
              <p>10.1. International orders may be subject to import duties, taxes, and customs fees levied by the destination country. These charges are the responsibility of the customer and will not be covered by Defy Lifestyle.</p>
              <p>10.2. Customs policies vary widely from country to country; please check with your local customs office for more information.</p>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default ShippingPolicy
