import React from 'react'
import Container from './Container'

const BreadCrumb = (props) => {
  const { title } = props
  return (
    <Container class1='breadcrumb mb-0 pt-[2.5%] section-heading
     '>
      <div className="row">
        <div className="col-12 ">
          <p className='text-center mb-0'>
            {title}
          </p>
        </div>
      </div>

    </Container>
  )
}

export default BreadCrumb
