import React from 'react';
import './Hero.css';
import { Carousel } from 'react-bootstrap';
import { Link } from "react-router-dom"


const Hero = () => {
  return (
    <div className='first-carousel'>
      <Carousel pause={false} interval={4000} showThumbs={false} emulateTouch={true} dynamicHeight={true}
      //  fade={true} interval={4000} className='custom-carousel'
      >
        <Carousel.Item>
          <div className='hero' style={{
            backgroundImage: `url(${require('../assests/hero-1.jpg')})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
          }}>
            <div className='overlay'></div>
            <div className='content'>
              <p>Live In</p>
              <p>Half Shirts</p>
              <p>Defy Lifestyle</p>
              <p>Made By Rebels For All Rebels</p>
              <Link to="/halfshirts">
                <button className='button'>Shop Now
                </button></Link>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className='hero' style={{
            backgroundImage: `url(${require('../assests/hero-2.jpg')})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
          }}>
            <div className="overlay"></div>
            <div className='content'>
              <p>Live In</p>
              <p>Full Shirts</p>
              <p>Embrace Simplicity</p>
              <p>Made For Comfort And Style</p>

              <Link to="/fullshirts">
                <button className='button'>Shop Now
                </button></Link>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className='hero' style={{
            backgroundImage: `url(${require("../assests/Defy-64-min.jpg")})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
          }}>
            <div className="overlay"></div>
            <div className='content'>
              <p>Live In</p>
              <p>Cotton Pants</p>
              <p>Rebel Spirit</p>
              <p>Made To Stand Out</p>

              <Link to="/cottonpants">
                <button className='button'>Shop Now
                </button></Link>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>

  );
};

export default Hero;
