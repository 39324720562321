import React from 'react';
import Container from '../components/Container';
import Meta from '../components/Meta'
import BreadCrumb from '../components/BreadCrumb'
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <>
      <Meta title={"Privacy Policy"} />
      <BreadCrumb title="Privacy Policy" />
      <Container class1="policy-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="policy">
              <p>Effective Date: 1/08/2023</p>
              <p>Thank you for visiting Defy Lifestyle's Privacy Policy Page. This privacy policy explains how we collect, use, disclose, and safeguard your personal information. By accessing or using our website, you consent to the practices described in this policy. Please read this privacy policy carefully to understand our practices regarding your personal data.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">1. Information We Collect</h2>
              <p>1.1. Personal Information: When you visit our website, create an account, place an order, or communicate with us, we may collect personal information such as your name, email address, shipping address, billing address, phone number, and payment information.</p>
              <p>1.2. Non-Personal Information: We may also collect non-personal information, such as your IP address, browser type, device information, and website usage data. This information is collected to improve our website's functionality and enhance your browsing experience.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">2. How We Use Your Information</h2>
              <p>2.1. Personalization: We use your personal information to personalize your shopping experience, process your orders, and provide customer support.</p>
              <p>2.2. Communication: We may use your email address or phone number to send you order updates, promotional offers, and other marketing communications. You can opt-out of these communications at any time.</p>
              <p>2.3. Payment Processing: We use your payment information to process transactions securely and prevent fraudulent activities.</p>
              <p>2.4. Website Improvement: Non-personal information helps us analyze website traffic, monitor trends, and improve our website's content and functionality.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">3. How We Share Your Information</h2>
              <p>3.1. Third-Party Service Providers: We may share your personal information with third-party service providers who assist us in processing payments, fulfilling orders, and delivering products.</p>
              <p>3.2. Legal Compliance: We may disclose your personal information if required by law or in response to valid legal requests, such as court orders or government investigations.</p>
              <p>3.3. Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">4. Cookies and Tracking Technologies</h2>
              <p></p>

              <h2 class="text-xl font-semibold mt-8 mb-2">5. Data Security</h2>
              <p>5.1. We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, no data transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute data security.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">6. Your Rights</h2>
              <p>6.1. Access and Correction: You have the right to access and update your personal information stored in our system. You can do this by logging into your account or contacting our customer support team.</p>
              <p>6.2. Data Deletion: You can request the deletion of your personal information from our records by contacting our customer support team. However, please note that we may retain certain information as required by law or for legitimate business purposes.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">7. Children's Privacy</h2>
              <p>7.1. Our website is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have inadvertently collected information from a child under 13, please contact us, and we will promptly delete the information.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">8. Third-Party Links</h2>
              <p>8.1. Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of any third-party websites you visit.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">9. Changes to this Privacy Policy</h2>
              <p>9.1. We may update this privacy policy from time to time to reflect changes in our practices or legal requirements. The most current version will be posted on our website, and the effective date will be indicated at the beginning of the policy.</p>

              <h2 class="text-xl font-semibold mt-8 mb-2">10. Contact Us</h2>
              <p>10.1. If you have any questions, concerns, or requests regarding this privacy policy or your personal information, please <Link to='/contact' className='underline'>contact us</Link>.</p>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default PrivacyPolicy
