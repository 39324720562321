import React, { useState, useEffect } from 'react';
import Container from '../components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../features/user/userSlice';
import Meta from '../components/Meta';
import { Link } from 'react-router-dom';
import QRmodal from '../components/QRmodel';

// import BreadCrumb from '../components/BreadCrumb';
const Orders = () => {
  const getTokenFromLocalStorage = localStorage.getItem('customer')
    ? JSON.parse(localStorage.getItem('customer'))
    : null;

  const config2 = {
    headers: {
      Authorization: `Bearer ${getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ''
        }`,
      Accept: 'application/json',
    },
  };

  const dispatch = useDispatch();
  const orderState = useSelector(state => state?.auth?.getorderedProduct?.orders);
  useEffect(() => {
    dispatch(getOrders(config2));
    // eslint-disable-next-line
  }, []);
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const handleOptionSelect = (orderId) => {
    setSelectedOrderId(orderId);
    setModalVisible(true);
  };

  return (
    <>
      {/* <BreadCrumb title="My Orders" /> */}
      <Meta title={'My Orders'} />
      <Container class1='cart-wrapper home-wrapper-2 py-12'>
        {orderState?.length === 0 ? (
          <div className='flex justify-center align-items-center'>
            <div>
            </div>
            <div className='no-wish-contents'>
              <div classname="lottie">
                <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_iikbn1ww.json" background="transparent" speed="1" style={{ width: "50%", height: "50%" }} loop autoplay></lottie-player>              </div>
              <h2>Your Order List Is Empty!</h2>
              <div className='flex align-middle justify-around'>
                <Link to="/product"><button className='order-empty'>Continue to Shopping</button></Link>
              </div>
            </div>
          </div>
        ) : (
          <div className='row my-orders'>
            <div className='col-12 mt-[.75%]'>
              {orderState && orderState?.map((item, index) => {

                return (<div>
                  <div>
                    <h1 className='section-heading mb-2'>Order ID <br />
                      #{item?.id}</h1>
                  </div>
                  <div className='flex items-center justify-center'>
                    <button
                      className={`w-30 py-2 px-14 rounded-lg flex justify-center items-center ${item?.orderStatus === 'Payment Pending' ? 'bg-black text-white' : 'bg-gray-400 text-gray-700'
                        }`}
                      disabled={item?.orderStatus !== 'Payment Pending'}
                      onClick={() => handleOptionSelect(item.id)}
                    >
                      {item?.orderStatus === 'Payment Pending' ? 'Process Payment' : 'Paid'}
                    </button>

                  </div>
                  <div style={{ backgroundColor: '#febd69' }} className='row pt-[1%] my-[3%] text-center' key={index}>
                    {/* <div className='col-4 pr-[3.5%] pl-[2%] overflow-x-auto' style={{ overflowX: 'auto', WebkitOverflowScrolling: 'touch' }}>
                      <p>{item?.id}</p>
                    </div> */}
                    <div className='col-3  px-[3.5%]'>
                      <h5 className='font-bold'>Sub Total</h5>
                      <p>{item?.totalPrice}</p>
                    </div>
                    <div className='col-3  px-[3.5%]'>
                      <h5 className='font-bold'>Grand Total</h5>
                      <p>{item?.totalPriceAfterDiscount}</p>
                    </div>
                    <div className='col-3  px-[3.5%]'>
                      <h5 className='font-bold'>Ordered Date</h5>
                      <p>{item?.createdAt ? formatDate(item.createdAt) : ''}</p>
                    </div>
                    <div className='col-3  px-[3.5%]'>
                      <h5 className='font-bold'>Status</h5>
                      <p>{item?.orderStatus}</p>
                    </div>
                    <div className='col-12'>
                      <div className='row py-[2.5%]' style={{ backgroundColor: '#232f3e' }}>
                        <div className='col-3 px-[3.5%]'>
                          <h6>Product Name</h6>
                        </div>
                        <div className='col-3 px-[3.5%]'>
                          <h6>Quantity</h6>
                        </div>
                        <div className='col-2 px-[3.5%]'>
                          <h6>Price</h6>
                        </div>
                        <div className='col-2 px-[3.5%]'>
                          <h6>Color</h6>
                        </div>
                        <div className='col-2 px-[3.5%]'>
                          <h6>Size</h6>
                        </div>
                        {item?.orderedItems?.map((i, index) => {
                          return (
                            <div className='col-12'>
                              <div className='row p-[1.1%]'>
                                <div className='col-3  px-[3.5%]'>
                                  <p className='text-white'>{i?.product?.title}</p>
                                </div>
                                <div className='col-3  px-[3.5%]'>
                                  <p className='text-white'>{i?.quantity}</p>
                                </div>
                                <div className='col-2  px-[3.5%]'>
                                  <p className='text-white'>{i?.price}</p>
                                </div>
                                <div className='col-2  px-[3.5%]'>
                                  <p>
                                    <ul className='colors ps-0 flex justify-center'>
                                      <div className='bg-white p-[3.5%]'>
                                        <li style={{ backgroundColor: i?.product?.color[0]?.title }}></li>
                                      </div>
                                    </ul>
                                  </p>
                                </div>
                                <div className='col-2  px-[3.5%]'>
                                  <p className='text-white'>{i?.size}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                );
              })}
              <QRmodal
                open={modalVisible}
                hideModal={() => setModalVisible(false)}
                title="Defy Lifestyle"
                orderId={selectedOrderId}
                totalAmount={orderState?.find(item => item.id === selectedOrderId)?.totalPriceAfterDiscount}
              />
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default Orders;
