
import React, { useEffect, useState } from 'react'
import { NavLink, Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import { CiShoppingCart, CiHeart, CiUser, CiSearch, } from 'react-icons/ci'
import Logo from '../assests1/defy_logo-removebg-preview.png';
import { useDispatch, useSelector } from 'react-redux'
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css"
import { MdOutlineClose } from 'react-icons/md';
import { CSSTransition } from 'react-transition-group';
import { getAProduct, getAllProducts } from '../features/products/productSlice';



const Search = () => {
  const [showTypeahead, setShowTypeahead] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const redirectedFrom = urlParams.get('redirectedFrom');

  const handleCloseButtonClick = () => {
    setShowTypeahead(true);
  };
  const productState = useSelector(state => state?.product?.product)
  const [productOpt, setProductOpt] = useState([])
  const authState = useSelector(state => state?.auth)
  const [paginate, setPaginate] = useState(true);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  useEffect(() => {
    let data = []
    for (let index = 0; index < productState?.length; index++) {
      const element = productState[index];
      data.push({ id: index, prod: element?.id, name: element?.title })
    }
    setProductOpt(data)
  }, [productState])


  const handleLogout = () => {
    localStorage.clear()
    window.location.reload()
  }
  return (
    <>
      <header className="header-bottom py-1 ">
        <div className='container-xxl'>
          <div className='row'>
            <div className='col-12'>
              <div className="d-flex align-items-center justify-center gap-30 py-[2px]">
                <h1 className='text-white uppercase'>Made By Rebels For All Rebels</h1>
              </div>
            </div>
          </div>
        </div>
      </header>
      <header className='header2'>
        <div className='m-2 p-3'>
          <div className='row align-items-center'>
            <div className="col-12 px-[3%]">
              <div className="header-upper-links search-bar flex align-items-center justify-end gap-[2%] ">
                <div className='my-menu-class-light input-group' >
                  <CSSTransition
                    in={showTypeahead}
                    timeout={300}
                    classNames="typeahead-animation"
                    unmountOnExit
                  >

                    <Typeahead
                      menuClassName={'my-menu-class-light'}
                      id="pagination-example"
                      onPaginate={() => console.log(setPaginate)}
                      onChange={(selected) => {
                        dispatch(getAProduct(selected[0]?.prod));
                        navigate(`/product/${selected[0]?.prod}`);
                      }}
                      onInputChange={(text) => {
                        setInputValue(text);
                        if (text.length === 1) {
                          dispatch(getAllProducts()); // Dispatch getAllProducts when the input value has one character
                        }
                      }}
                      options={productOpt}
                      minLength={3}
                      paginate={paginate}
                      labelKey={"name"}
                      inputProps={{
                        style: { backgroundColor: 'white', color: ' black' },
                        placeholder: 'Search for products here...',
                        className: "typing-black"
                      }}
                    />
                  </CSSTransition>

                  <span className='fs-2 bg-white'>
                    <Link to={redirectedFrom}>
                      <MdOutlineClose
                        className={'lower lower-cross fs-2 ml-3 cursor-pointer'}
                        onClick={handleCloseButtonClick}
                      /></Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header >
      <div className='m-10 p-10'></div>
    </>
  )
}

export default Search;



