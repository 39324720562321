import React from 'react'
import Container from '../components/Container'
import Meta from '../components/Meta'
import BreadCrumb from '../components/BreadCrumb'
import { Link } from 'react-router-dom';

const RefundPolicy = () => {
  return (
    <>
      <Meta title={"Refund Policy"} />
      <BreadCrumb title="Refund Policy" />
      <Container class1="policy-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="policy">
              <p>Effective Date: 1/08/2023</p>
              <p>1. Refund Eligibility</p>
              <p class="ml-2">1.1. We want you to be fully satisfied with your purchase from Defy Lifestyle. If you are not entirely happy with your order, you may be eligible for a refund subject to the terms and conditions outlined in this policy.</p>
              <p class="ml-2">1.2. To be eligible for a refund, you must meet the following criteria:</p>
              <ul className="list-disc ml-8">
                <li>The refund request is made within 7 days from the date of delivery.</li>
                <li>The item is in its original condition, unused, and with all original packaging and tags intact.</li>
                <li>Personalized or custom-made items are not eligible for refunds, except in the case of a defect or error on our part.</li>
              </ul>

              <p>2. Refund Process</p>
              <p class="ml-2">2.1. To initiate a refund request, please contact our customer support team. Provide your order number and the reason for the refund request.</p>
              <p class="ml-2">2.2. Once your refund request is received and approved, we will provide you with instructions on how to return the item.</p>
              <p class="ml-2">2.3. Please note that you are responsible for the cost of shipping the item back to us, except in cases of defective or incorrect items.</p>

              <p>3. Inspection and Approval</p>
              <p class="ml-2">3.1. Upon receiving the returned item, we will inspect it to ensure it meets the refund eligibility criteria stated in this policy.</p>
              <p class="ml-2">3.2. If the item meets the required conditions, we will process the refund. The refund amount will be credited to the original payment method used for the purchase.</p>

              <p>4. Refund Processing Time</p>
              <p class="ml-2">4.1. Refunds will be processed within 2-10 business days of receiving the returned item and confirming its eligibility.</p>
              <p class="ml-2">4.2. Depending on your payment provider's policies, it may take additional time for the refunded amount to appear in your account.</p>

              <p>5. Non-Refundable Items</p>
              <p class="ml-2">5.1. The following items are non-refundable:</p>
              <ul class="list-disc ml-8">
                {/* <li>Gift cards and e-gift cards.</li> */}
                {/* <li>Downloadable digital products.</li> */}
                <li>Products that have been used, damaged, or altered from their original condition.</li>
                <li>Items returned after the specified refund period.</li>
              </ul>

              <p>6. Damaged or Defective Items</p>
              <p class="ml-2">6.1. If you receive a damaged or defective item, please contact us immediately. We will provide instructions on returning the item, and upon inspection, we will either issue a full refund or send a replacement at no additional cost.</p>

              <p>7. Changes to this Refund Policy</p>
              <p class="ml-2">7.1. We may update this refund policy from time to time to reflect changes in our practices or legal requirements. The most current version will be posted on our website, and the effective date will be indicated at the beginning of the policy.</p>

              <p>8. Contact Us</p>
              <p class="ml-2">8.1. If you have any questions, concerns, or requests regarding this refund policy or the refund process, please <Link to='/contact' className='underline'>contact</Link> .</p>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default RefundPolicy
