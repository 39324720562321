
import React, { useEffect, useState } from 'react'
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom'
import { CiShoppingCart, CiHeart, CiUser, CiSearch, CiHome, CiShop } from 'react-icons/ci'
import Logo from '../assests1/defy_logo-removebg-preview.png';
import { useSelector } from 'react-redux'
import "react-bootstrap-typeahead/css/Typeahead.css"
import "../styles.css"

const Header = () => {
  const [showTypeahead, setShowTypeahead] = useState(false);
  const currentPath = useLocation().pathname;

  const handleSearchIconClick = () => {
    setShowTypeahead(!showTypeahead);
  };



  const [color, setColor] = useState(false)
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true)
    } else { setColor(false) }
  }

  window.addEventListener('scroll', changeColor)







  const productState = useSelector(state => state?.product?.product)
  const [productOpt, setProductOpt] = useState([])
  const authState = useSelector(state => state?.auth)

  useEffect(() => {
    let data = []
    for (let index = 0; index < productState?.length; index++) {
      const element = productState[index];
      data.push({ id: index, prod: element?.id, name: element?.title })

    }
    setProductOpt(data)
  }, [productState])
  const [showDropdown, setShowDropdown] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleLogout = () => {
    localStorage.clear()
    window.location.reload()
  }
  return (
    <>
      <header className={color ? 'header header-bg ' : 'header mt-4'
      }>
        <div className='mx-2'>
          <div className='row align-items-center'>
            <div className="col-6 flex justify-start">
              <NavLink to={'/'}><img src={Logo} alt="Defy logo" className={color ? 'lower-header-logo header-logo w-[60%]' : 'upper-header-logo header-logo w-[60%]'} /></NavLink>
            </div>
            <div className="col-6 px-[3%]">
              <div className="header-upper-links search-bar flex align-items-center justify-end gap-[4%]">
                <div className={color ? 'my-menu-class-light input-group' : 'my-menu-class-dark input-group'}>

                  <span className='fs-2 bg-inherit'>
                    <Link to={`/search?redirectedFrom=${encodeURIComponent(currentPath)}`}>
                      <CiSearch
                        className={color ? 'lower  mt-[10px] ml-3 cursor-pointer' : 'upper  mt-[10px] ml-3 cursor-pointer'}
                        onClick={handleSearchIconClick}
                      />
                    </Link>
                  </span>


                </div>
                <div>
                  <Link className='d-flex align-items-center text-black' to="/product">
                    <CiShop alt="heart" className={color ? 'lower fs-2' : 'upper fs-2'} />
                    {/* <p className='mb-0'>Favourite <br /> Wishlist
                    </p>  */}
                  </Link>
                </div>
                <div>
                  <Link className='d-flex align-items-center text-black' to="/wishlist">
                    <CiHeart alt="heart" className={color ? 'lower fs-2' : 'upper fs-2'} />
                    {/* <p className='mb-0'>Favourite <br /> Wishlist
                    </p>  */}
                  </Link>
                </div>

                <div>
                  <div className='user-menu'>
                    {authState?.user === null ? (
                      <Link className='d-flex align-items-center text-black' to="/login">
                        <CiUser alt="User" className={color ? 'lower fs-2' : 'upper fs-2'} />
                      </Link>
                    ) : (
                      <button className='d-flex align-items-center text-black' onClick={toggleDropdown}>
                        <CiUser alt="User" className={color ? 'lower fs-2' : 'upper fs-2'} />
                        <p className='mb-0'>
                        </p>
                      </button>
                    )}
                    {/* Dropdown content */}
                    {showDropdown && (
                      <ul className="dropdown-menu">
                        <li>
                          <Link to="/my-profile">Update Profile</Link>
                        </li>
                        {/* Add more links here as needed */}
                        <li>
                          <Link to="/my-orders">My Orders</Link>
                        </li>
                        <li>
                          <Link to='/contact'>Contact</Link>
                        </li>
                        <li>
                          <Link onClick={handleLogout}>Logout</Link>
                        </li>

                        {/* ... */}
                      </ul>
                    )}
                  </div>


                </div>
                <div>

                  <Link className='d-flex align-items-center text-black' to="/cart">
                    <CiShoppingCart alt="Cart" className={color ? 'lower fs-2' : 'upper fs-2'} />
                    <div className='flex flex-col'>

                      {/* <span className='badge bg-white text-dark'>{cartState?.length ? cartState?.length : 0}</span> */}
                      {/* <p className='mb-0'>Rs {total ? total : 0}</p> */}

                    </div>
                  </Link>

                </div>

              </div>
            </div>
          </div>
        </div>
        {/* </nav> */}

      </header >
      <header className="header-bottom py-1 ">
        <div className='container-xxl'>
          <div className='row'>
            <div className='col-12 text-center'>
              <div className=" d-flex align-items-center justify-center gap-30 py-[2px]">
                <h1 className='text-white uppercase'>Made By Rebels For All Rebels</h1>

                {/* <div className='menu-links'>
                  <div className=''>
                    <NavLink to="/">Home</NavLink>
                    <NavLink to="/product">Our Store</NavLink>
                    <NavLink to="/my-orders">My Orders</NavLink>
                    <NavLink to="/about">About</NavLink>
                    <NavLink to="/contact">Contact</NavLink>
                    {authState?.user ? (
                      <NavLink><button onClick={handleLogout} className='text-uppercase text-white'>Logout</button></NavLink>

                    ) : null}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header;



