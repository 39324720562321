import React from 'react'
import Container from '../components/Container'

const About = () => {
  return (
    <Container class1="">
      <div className="row">
        About
      </div>
    </Container>
  )
}

export default About
