import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { addToWishlist, getAProduct } from '../features/products/productSlice';
import { AiOutlineHeart } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const ProductCard = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });
  const isLarge = useMediaQuery({ minWidth: "992px", maxWidth: "1199.98px" });

  const getTokenFromLocalStorage = localStorage.getItem('customer')
    ? JSON.parse(localStorage.getItem('customer'))
    : null;

  const config2 = {
    headers: {
      Authorization: `Bearer ${getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ''
        }`,
      Accept: 'application/json',
    },
  };
  const { grid, data } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation()
  const addToWish = (id, config) => {
    dispatch(addToWishlist({ id, config }));
  };
  // const getProductId = location.pathname.split("/")[2]
  // const [isLoading, setIsLoading] = useState(true);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000); // Simulate a 2-second loading delay

  //   return () => clearTimeout(timer); // Clean up the timer on unmounting
  // }, []);
  return (
    <>
      {data && data.map((item, index) => (
        <div key={index} className={`gr-${grid} cursor-pointer`}>
          <div
            onClick={() => {
              dispatch(getAProduct(item?.id));
              navigate(`/product/${item?.id}`);
            }}
          >
            <div className="product-card position-relative">
              <div
                className="absolute wish-icon-box cursor-pointer"
                onClick={(e) => {
                  addToWish(item?.id, config2);
                  e.stopPropagation();
                }}
              >
                <AiOutlineHeart className="fs-4" />
              </div>
              {/* {isLoading ? (
                // Shimmer effect placeholder with centered loading line
                <div className="shimmer-placeholder">
                  <div className="loading-line-container flex justify-center top-[50%]">
                    <div className="loading-line"></div>
                  </div>
                  <Skeleton
                    height={isLarge ? 250 : (isDesktop ? 300 : 200)}
                    width={160}
                    className="img-fluid d-block mx-auto"
                  />
                  <Skeleton
                    height={isLarge ? 40 : (isDesktop ? 50 : 30)}
                    width={160}
                  />
                  <Skeleton
                    height={isLarge ? 40 : (isDesktop ? 50 : 30)}
                    width={160}
                  />
                  <Skeleton
                    height={isLarge ? 40 : (isDesktop ? 50 : 30)}
                    width={160}
                  />

                </div>
              ) : ( */}
              {/* // Actual content */}
              <>
                <div className="product-image">
                  <img
                    src={item?.images[0]?.url}
                    className="img-fluid d-block mx-auto"
                    alt="Product 1"
                    width={160}
                  />
                  <img src={item?.images[1]?.url} className="img-fluid" alt="Product 2" />
                </div>
                <div className="product-details">
                  <h5 className="product-title">{item?.title}</h5>
                  <p
                    className={`description ${grid === 12 ? 'd-block' : 'd-none'}`}
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  ></p>
                  <p className="price">Rs.{item?.price}</p>
                </div>
              </>
              {/* )} */}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default ProductCard;
