import React, { useEffect } from 'react'
import Meta from '../components/Meta'
import { useState } from 'react'
import ProductCard from '../components/ProductCard'
import { useDispatch, useSelector } from "react-redux"
import Container from '../components/Container'
import { getAllProducts } from '../features/products/productSlice'
import { useMediaQuery } from 'react-responsive';
import BreadCrumb from "../components/BreadCrumb"
const HalfShirt = () => {
  const isSmall = useMediaQuery({ maxWidth: 575.98 });
  const isMiddle = useMediaQuery({ minWidth: 576, maxWidth: 991.98 });
  const width = isSmall ? '30px' : isMiddle ? '80px' : '100px';
  const [productsToShow, setProductsToShow] = useState(12);
  const productsPerLoad = 12;
  const handleScroll = () => {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;
    if (scrollTop + clientHeight >= scrollHeight - 20) {
      setProductsToShow(prevProducts => prevProducts + productsPerLoad);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const [grid, setGrid] = useState(4);
  const productState = useSelector((state) => state?.product?.product);
  const dispatch = useDispatch();
  const [sort, setSort] = useState(null)
  useEffect(() => {
    let category = []
    let newtags = [];
    let newColors = []
    for (let index = 0; index < productState.length; index++) {
      const element = productState[index];
      category.push(element.category)
      newtags.push(element.tags)
      newColors.push(element.color)
    }
  }, [productState])
  useEffect(() => {
    getProducts()
    // eslint-disable-next-line
  }, [sort])
  const getProducts = () => {
    dispatch(getAllProducts({ category: 'half shirt', sort }))
  }
  const newProducts = productState ? productState.filter(product => product.category === "Half Shirt") : [];
  const newProductsCount = newProducts.length;
  return (
    <>
      <Meta title={"Half Shirts"} />
      <BreadCrumb title="Half Shirts" />
      <Container class1="store-wrapper home-wrapper-2 pb-[5%] pt-[2.5%]">
        <div className="row">
          <div className="col-12">
            <div className="filter-sort-grid mb-[3%]">
              <div className="d-flex justify-content-between align-items-center">
                <div className='d-flex align-items-center '>
                  <p className="mb-0 d-block store-sort" style={{ width }}>Sort By:</p>
                  <select name="" id="" className='form-control form-select py-[2%] ' onChange={(e) => setSort(e.target.value)}>
                    <option value="title" className='store-option'>Alphabetically, A-Z</option>
                    <option value="-title">Alphabetically,Z-A</option>
                    <option value="price">Price, low to high</option>
                    <option value="-price">Price, high to low</option>
                  </select>
                </div>
                <div className='d-flex align-items-center grid'>
                  <p className="totalproducts mb-0">
                    {newProductsCount} Products
                  </p>
                </div>
              </div>
            </div>
            <div className="products-list pb-[3%]">
              <div className="d-flex justify-around flex-wrap" style={{ gap: '0%' }}>
                <ProductCard
                  data={newProducts ? newProducts.slice(0, productsToShow) : []}
                  grid={isSmall ? 6 : isMiddle ? 4 : 3}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default HalfShirt
