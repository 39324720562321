import React from "react";

import { Modal, Button } from "antd";

const QR = require("../assests/Defy-QR.jpg")
const QRmodal = (props) => {
  const { open, hideModal, orderId, totalAmount } = props;



  return (
    <Modal
      visible={open}
      onCancel={hideModal}
      footer={null}
      closable={false}
      maskClosable={false}
    >
      <div className="flex flex-col items-center">
        <h2 className="text-2xl font-bold mb-4">Defy Lifestyle</h2>
        {/* Your QR code component goes here (replace with actual QR code component) */}
        <div className="bg-gray-300 w-48 h-48 flex items-center justify-center mb-4">
          <img src={QR} alt="QR Code" style={{ width: "100%", height: "100%" }} />
        </div>
        <p className="text-lg mb-0 font-bold">Your Order ID is {orderId}</p>
        <p className="text-lg mb-1 font-bold">Grand Total = {totalAmount}</p>

        <p className="text-sm mb-1">Scan the QR code to make the payment.</p>
        <p className="text-md mb-1">Please provide the remarks with the Order ID.</p>
        <p className="text-md font-bold mb-2">Remarks: Order Number {orderId}</p>
        <p className="text-md mb-2 text-center">
          "We will commence order processing upon the receival of your payment. Please ensure timely payment within 24 hours from the order placement to avoid order rejection."</p>
        <p className="text-lg mb-4 text-center">Thank You!</p>
        <Button type="link" onClick={hideModal} className="text-gray-500 text-lg">
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default QRmodal;
