import React from 'react'
import Container from '../components/Container'
import Meta from '../components/Meta'
import BreadCrumb from '../components/BreadCrumb'
import { Link } from 'react-router-dom';

const TermsandConditions = () => {
  return (
    <>
      <Meta title={"Terms And Conditions"} />
      <BreadCrumb title="Terms And Conditions" />
      <Container class1="policy-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="policy">
              {/* <div class="container mx-auto px-4 py-8"> */}
              {/* <h1 class="text-3xl font-bold mb-4">Terms and Conditions for Defy Lifestyle</h1> */}

              <p class="mb-4">Welcome to Defy Lifestyle's Terms and Conditions. These terms and conditions govern your use of our
                website. By accessing or using our website, you acknowledge that you have read, understood, and agree to be
                bound by these terms and conditions. If you do not agree to these terms, please refrain from using our
                website.</p>

              {/* <!-- Example section --> */}
              <h2 class="text-xl font-bold mt-8 mb-4">Acceptance of Terms</h2>
              <p class="mb-4">1.1 By using our website, you represent and warrant that you are of legal age to form a binding
                contract with Defy Lifestyle, or you have obtained parental or guardian consent to use the
                website.</p>
              <p class="mb-4">1.2 If you are using the website on behalf of a company or organization, you further represent
                and warrant that you have the authority to bind that company or organization to these terms.</p>

              {/* <!-- Products and Services --> */}
              <h2 class="text-xl font-bold mt-8 mb-4">Products and Services</h2>
              <p class="mb-4">2.1 Defy Lifestyle offers a variety of products and services for purchase. All
                product descriptions, prices, and availability are subject to change without prior notice.</p>
              <p class="mb-4">2.2 While we make every effort to display the most accurate information, we do not warrant that
                product descriptions, images, or other content on our website are error-free or complete.</p>

              {/* <!-- Ordering and Payments --> */}
              <h2 class="text-xl font-bold mt-8 mb-4">Ordering and Payments</h2>
              <p class="mb-4">3.1 To place an order on our website, you must provide accurate and up-to-date information,
                including payment details.</p>
              <p class="mb-4">3.2 By submitting an order, you authorize us to charge the provided payment method for the total
                amount of your order, including applicable taxes, fees, and shipping costs.</p>
              <p class="mb-4">3.3 We reserve the right to refuse or cancel any order at our discretion, including but not
                limited to orders with incorrect pricing or product availability information.</p>

              {/* <!-- User Accounts --> */}
              <h2 class="text-xl font-bold mt-8 mb-4">User Accounts</h2>
              <p class="mb-4">4.1 You may be required to create a user account to access certain features or make purchases on
                our website.</p>
              <p class="mb-4">4.2 You are responsible for maintaining the confidentiality of your account details, including
                your username and password. You agree to be held liable for activities conducted under your account.</p>
              <p class="mb-4">4.3 If you suspect unauthorized access to your account, you must notify us immediately to take
                appropriate actions.</p>

              {/* <!-- Prohibited Activities --> */}
              <h2 class="text-xl font-bold mt-8 mb-4">Prohibited Activities</h2>
              <p class="mb-4">5.1 Users are prohibited from engaging in any activity that may:</p>
              <ul class="list-disc list-inside mb-4">
                <li>Violate any applicable laws, regulations, or third-party rights.</li>
                <li>Interfere with the proper functioning of the website or its services.</li>
                <li>Transmit harmful code, viruses, or malware.</li>
                <li>Engage in fraudulent activities or misrepresentations.</li>
              </ul>

              {/* <!-- Intellectual Property --> */}
              <h2 class="text-xl font-bold mt-8 mb-4">Intellectual Property</h2>
              <p class="mb-4">6.1 All content and materials on this website, including but not limited to text, images, logos,
                and trademarks, are the property of Defy Lifestyle and protected by applicable copyright and
                intellectual property laws.</p>
              <p class="mb-4">6.2 You may not reproduce, distribute, modify, display, or use any content from our website
                without prior written consent from Defy Lifestyle.</p>

              {/* <!-- Liability and Indemnity --> */}
              <h2 class="text-xl font-bold mt-8 mb-4">Liability and Indemnity</h2>
              <p class="mb-4">7.1 Defy Lifestyle shall not be liable for any direct, indirect, incidental, or
                consequential damages arising from the use or inability to use our website or products.</p>
              <p class="mb-4">7.2 By using our website, you agree to indemnify and hold Defy Lifestyle harmless
                from any claims, damages, or liabilities arising out of your use of our website or breach of these terms.</p>

              {/* <!-- Termination --> */}
              <h2 class="text-xl font-bold mt-8 mb-4">Termination</h2>
              <p class="mb-4">8.1 Defy Lifestyle reserves the right to terminate or suspend access to our
                website at any time for any reason, without prior notice.</p>

              {/* <!-- Modifications --> */}
              <h2 class="text-xl font-bold mt-8 mb-4">Modifications</h2>
              <p class="mb-4">9.1 We may update or modify these terms and conditions from time to time. Any changes will be
                effective immediately upon posting on this page.</p>
              <p class="mb-4">9.2 Your continued use of the website after the changes constitute your acceptance of the modified
                terms.</p>

              {/* <!-- Governing Law --> */}
              <h2 class="text-xl font-bold mt-8 mb-4">Governing Law</h2>
              <p class="mb-4">10.1 These terms and conditions shall be governed by and construed in accordance with the laws of
                Nepal.</p>
              <p class="mb-4">10.2 Any legal action or proceeding relating to these terms and conditions shall be brought exclusively in the courts located within Kathmandu, Nepal.




              </p>

              {/* <!-- Contact Us --> */}
              <h2 class="text-xl font-bold mt-8 mb-4">Contact Us</h2>
              <p class="mb-4">11.1 If you have any questions or concerns about these terms and conditions, please <Link to='/contact' className='underline'>Contact us</Link></p>
            </div>

            {/* </div> */}
          </div>
        </div>
      </Container>
    </>
  )
}

export default TermsandConditions
